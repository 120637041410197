import { COMMON } from './common';

export const REST_URL = COMMON.BASE_URL;
export const REST_ROOT_URL = COMMON.ROOT_URL;
export const REST_COMMON_URL = COMMON.COMMON_URL;
export const REST_GUEST_URL = COMMON.GUEST_URL;

export const REST_API = {
  COMMON: {
    STORES: `${REST_GUEST_URL}/booking-stores`,
    ZIPCODEADDRESS: `${REST_COMMON_URL}/zipcode`,
  },
  GUEST: {
    MEMBERENTRY: `${REST_GUEST_URL}/member-entries`,
    DUPLICATIONCHECKCONTRACT: `${REST_GUEST_URL}/duplication-check-contract`,
  },
  USER: {
    LOGIN: `${REST_URL}/authenticate`,
    ME: `${REST_URL}/me`,
    NOTICES_AGREE_ENTRY: `${REST_URL}/notices-agree-entries`,
  },
  TOP: {
    BANNER_LIST: `${REST_COMMON_URL}/banners`,
  },
  MYPAGE: {
    COMMON: `${REST_URL}/mypages`,
    CONTRACT: `${REST_URL}/contacts`,
    CHANGE_PASSWORD: `${REST_URL}/change-password`,
    REGULARS: `${REST_URL}/regulars`,
    REGULARS_ABSENCE: `${REST_URL}/regulars/absence/{id}`,
    REGULARS_ABSENCE_CANCEL: `${REST_URL}/regulars/absence-cancel/{id}`,
    REGULARS_TRANSFER_CANCEL: `${REST_URL}/regulars/transfer-cancel/{id}`,
    BOOKINGS: `${REST_URL}/bookings`,
    BOOKING_CONTENTS: `${REST_URL}/booking-contents`,
    BOOKING_CANCEL: `${REST_URL}/bookings/cancel`,
    BOOKING_CONFIRM: `${REST_URL}/bookings/confirm`,
    SURVEY_DETAIL: `${REST_URL}/surveys`,
    SURVEY: {
      UNANSWERED: `${REST_URL}/surveys/unanswered`,
      ANSWERED: `${REST_URL}/surveys/answered`,
      ALL: `${REST_URL}/surveys/all`,
    },
    POINT_HISTORY: {
      SUMMARY: `${REST_URL}/points/summary`,
      HISTORY: `${REST_URL}/points`,
    },
    ATTENDANCE_ONLINE_CULTURE: `${REST_URL}/attendance-online-cultures/{id}`,
    NOTICE_LIST: {
      NOTICES: `${REST_URL}/notices`,
    },
    SELECT_VALUES: `${REST_URL}/select-values`,
    CONTRACT_EDIT: `${REST_URL}/contract-edit`,
    MEMBER_EDIT: `${REST_URL}/member-edit`,
  },
  RESET_PASSWORD: {
    FORGOT: `${REST_COMMON_URL}/forgot`,
    CHECK_EXPIRATION: `${REST_COMMON_URL}/check-expiration`,
    RESET: `${REST_COMMON_URL}/reset-password`,
  },
  TRANSFER: {
    TRANSFER: `${REST_URL}/transfers`,
  },
  BOOKING: {
    SELECTS: `${REST_GUEST_URL}/booking-selects`,
    CULTURE: `${REST_GUEST_URL}/cultures`,
    RENTAL_COAT_TABLE_TENNIS: `${REST_GUEST_URL}/rental-coat-table-tennis`,
    RENTAL_COAT_BADMINTON: `${REST_GUEST_URL}/rental-coat-badminton`,
    ROOM: `${REST_GUEST_URL}/room-bookings`,
    GOLF: `${REST_GUEST_URL}/golf`,
    ONLINE_CULTURE: `${REST_GUEST_URL}/online-cultures`,
  },
  BOOKING_DETAIL: {
    BOOKING_DETAIL: `${REST_URL}/booking-detail`,
    BOOKING_DETAIL_GUEST: `${REST_GUEST_URL}/booking-detail-guest`,
  },
  BOOKING_DETAIL_CANCEL_WAIT: {
    BOOKING_DETAIL_CANCEL_WAIT: `${REST_URL}/booking-detail-cancel-wait`,
  },
};
