import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export function getBookingDetail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING_DETAIL.BOOKING_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getGuestBookingDetail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING_DETAIL.BOOKING_DETAIL_GUEST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      Promise.reject(error);
    });
}

export function createBookingDetail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.BOOKING_DETAIL.BOOKING_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function createGuestBookingDetail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.BOOKING_DETAIL.BOOKING_DETAIL_GUEST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
