import React, { Component } from 'react';
import { format, addMinutes } from 'date-fns';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Container, Box, Button, TextField, Paper,
  Grid, FormControl, withStyles,
} from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import BookingStepper from './components/bookingStepper/BookingStepper';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import Loading from '../../../components/atoms/loading/Loading';
import redirectLinks from '../../../constants/redirectLinks';
import {
  actCallApiGetBookingDetail, actCallApiGetGuestBookingDetail, actCallApiCreateBooking,
  actCallApiCreateGuestBooking, actResetCreateBookingFlgReset,
} from '../../../redux/booking/bookingDetail/action';
import BookingDisplayDetail from './components/bookingDisplayDetail/BookingDisplayDetail';
import CheckBooking from './components/checkBooking/CheckBooking';
import BookingDetailError from './components/bookingDetailError/BookingDetailError';
import CompleteBooking from './components/completeBooking/CompleteBooking';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import RequiredChip from '../../../components/atoms/requiredChip/RequiredChip';

const styles = (theme) => ({
  gridRoot: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  paper: {
    padding: theme.spacing(4),
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
});

class BookingDetail extends Component {
  constructor(props) {
    super(props);
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)mjwt\s*=\s*([^;]*).*$)|^.*$/, '$1');
    document.cookie = 'b_fi=; path=/; max-age=0';
    document.cookie = 'b_ft=; path=/; max-age=0';
    document.cookie = 'b_fc=; path=/; max-age=0';
    document.cookie = 'b_fk=; path=/; max-age=0';

    let bookingId = null;
    let golfTargetType = '';
    let contentId = null;
    let storeId = null;
    let isError = false;

    if (props.location.state && props.location.state.bookingId) {
      const locationState = props.location.state;
      bookingId = locationState.bookingId;
      if (locationState.golfTargetType) {
        golfTargetType = locationState.golfTargetType;
      }
      contentId = locationState.contentId;
      storeId = locationState.storeId;
    }

    if (props.location.state && !props.location.state.bookingId) {
      this.props.history.push({
        pathname: redirectLinks.BOOKING,
      });
      isError = true;
    }

    // 長潟のゴルフでなく、ログインしていなければ、ログイン画面へ
    if (!token && (contentId !== 2 || storeId !== 4)) {
      this.props.history.push({
        pathname: redirectLinks.LOGIN,
        state: {
          bookingId,
          golfTargetType,
          contentId,
        },
      });
      document.cookie = `b_fi=${bookingId}; path=/; max-age=3600`;
      document.cookie = `b_ft=${golfTargetType}; path=/; max-age=3600`;
      document.cookie = `b_fc=${contentId}; path=/; max-age=3600`;
      isError = true;
    }

    let isLogin = true;
    if (!token) {
      isLogin = false;
    }

    const guestInfo = {
      name: '',
      phoneBefore: '',
      phoneMiddle: '',
      phoneAfter: '',
      phoneNumber: '',
    };

    const message = {
      name: '',
      phoneNumber: '',
    };

    this.state = {
      token,
      key: {
        bookingId,
        golfTargetType,
        contentId,
      },
      isNagataGolf: contentId === 2 && storeId === 4,
      bookingData: {
        data: {},
        errorMessages: [],
        roomInfo: { room: {}, timeList: [] },
      },
      isLoading: false,
      activeIndex: 0,
      isComplete: false,
      completeData: {
        data: {},
        errorMessages: [],
      },
      selectedGolfBox: null,
      roomSelectedValue: [],
      dispEndTime: null,
      isLogin,
      guestInfo,
      message,
    };
    if (!isError && isLogin && props.common.user) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookingDetail.data !== this.props.bookingDetail.data) {
      if (this.props.bookingDetail.data) {
        this.setData(this.props.bookingDetail.data);
      }
    }
    if (prevProps.bookingDetail.isCreateSuccess !== this.props.bookingDetail.isCreateSuccess) {
      if (this.props.bookingDetail.isCreateSuccess) {
        this.setComplete(this.props.bookingDetail.successData);
      }
    }
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user.user) {
        if (this.props.common.user.user.id) {
          this.getData();
        }
      }
    }
  }

  onBookingListBackClick = () => {
    if (!this.state.token) {
      this.setState({ isLogin: false });
      return;
    }
    this.props.history.push(redirectLinks.BOOKING);
  }

  onBookingListBack = () => {
    this.props.history.push(redirectLinks.BOOKING);
  }

  onNextClick = (_, activeIndex) => {
    this.scrollToTop();
    this.setState({ activeIndex });
  }

  onFromDispNextClick = (_, value, activeIndex, selectedGolfBox) => {
    this.scrollToTop();
    const dispEndTime = this.endTimeCalc(value);
    this.setState({
      activeIndex, roomSelectedValue: value, dispEndTime, selectedGolfBox,
    });
  }

  onClickLogin = () => {
    const { key } = this.state;

    this.props.history.push({
      pathname: redirectLinks.LOGIN,
      state: {
        bookingId: key.bookingId,
        golfTargetType: key.golfTargetType,
        contentId: key.contentId,
      },
    });
    document.cookie = `b_fi=${key.bookingId}; path=/; max-age=3600`;
    document.cookie = `b_ft=${key.golfTargetType}; path=/; max-age=3600`;
    document.cookie = `b_fc=${key.contentId}; path=/; max-age=3600`;
  }

  onClickGuestReservation = () => {
    const { key, guestInfo } = this.state;
    const newMessage = {
      name: this.validationCheck('name', guestInfo.name),
      phoneNumber: this.validationCheck('phoneNumber', guestInfo.phoneNumber),
    };
    if (newMessage.name === '' && newMessage.phoneNumber === '') {
      this.props.dispatch(
        actCallApiGetGuestBookingDetail({
          bookingId: key.bookingId,
          golfTargetType: key.golfTargetType || null,
          contentId: key.contentId,
          userId: '',
          companyId: '',
          phoneNumber: guestInfo.phoneNumber,
        }),
      );
    } else {
      this.setState({
        message: newMessage,
      });
    }
  }

  getData() {
    const { key, guestInfo } = this.state;
    const { user } = this.props.common;
    const userId = user && user.user && user.user.id ? user.user.id : '';
    const companyId = user && user.store && user.store.mstOrganizationCompanyId ? user.store.mstOrganizationCompanyId : '';

    this.props.dispatch(
      actCallApiGetBookingDetail({
        bookingId: key.bookingId,
        golfTargetType: key.golfTargetType || null,
        contentId: key.contentId,
        userId,
        companyId,
        phoneNumber: guestInfo.phoneNumber,
      }),
    );
  }

  setData(bookingData) {
    this.setState({
      isLoading: false, bookingData, isLogin: true, activeIndex: 1,
    });
  }

  onFromCompleteBack = () => {
    this.scrollToTop();
    this.setState({ activeIndex: 1 });
  }

  onClickComplete = () => {
    this.scrollToTop();
    const {
      key, roomSelectedValue, bookingData, selectedGolfBox, guestInfo,
    } = this.state;
    const { user } = this.props.common;
    this.props.dispatch(actResetCreateBookingFlgReset());

    if (user && user.user && user.user.id) {
      this.props.dispatch(
        actCallApiCreateBooking({
          bookingId: key.bookingId,
          golfTargetType: key.golfTargetType || null,
          contentId: key.contentId,
          memberId: user.user.id,
          bookingIds: roomSelectedValue,
          storeId: bookingData.data.storeId,
          targetedOn: bookingData.data.targetedOn,
          golfBoxId: selectedGolfBox,
          startTime: bookingData.data.startTime,
          golfLessonBox: bookingData.data.lessonBoxNumber,
          name: `${user.user.lastName} ${user.user.firstName}`,
          phoneNumber: guestInfo.phoneNumber,
        }),
      );
    } else {
      this.props.dispatch(
        actCallApiCreateGuestBooking({
          bookingId: key.bookingId,
          golfTargetType: key.golfTargetType || null,
          contentId: key.contentId,
          memberId: null,
          bookingIds: roomSelectedValue,
          storeId: bookingData.data.storeId,
          targetedOn: bookingData.data.targetedOn,
          golfBoxId: selectedGolfBox,
          startTime: bookingData.data.startTime,
          golfLessonBox: bookingData.data.lessonBoxNumber,
          name: guestInfo.name,
          phoneNumber: guestInfo.phoneNumber,
        }),
      );
    }
    this.setState({ isLoading: true });
  }

  setComplete(completeData) {
    this.setState({
      isLoading: false, isComplete: true, completeData,
    });
  }

  validationCheck = (name, value) => {
    if (name === 'name' && !value.trim()) {
      return '入力して下さい';
    }
    if (name === 'phoneNumber') {
      const values = value.split('-');
      const regex = /^[0-9]*$/;
      let error = '';

      values.forEach((item) => {
        if (item && !regex.test(item)) {
          error = '正しい電話番号を入力してください';
        }
        if (!item) {
          error = '入力してください';
        }
      });
      return error;
    }
    return '';
  }

  onChangeGuestForm = (event) => {
    const { name, value } = event.target;
    const { guestInfo, message } = this.state;
    let phone = guestInfo.phoneNumber;
    let errorName = '';
    let errorMessage = '';
    if (name === 'phoneBefore') {
      phone = `${value}-${guestInfo.phoneMiddle}-${guestInfo.phoneAfter}`;
      errorName = 'phoneNumber';
      errorMessage = this.validationCheck('phoneNumber', phone);
    }
    if (name === 'phoneMiddle') {
      phone = `${guestInfo.phoneBufore}-${value}-${guestInfo.phoneAfter}`;
      errorName = 'phoneNumber';
      errorMessage = this.validationCheck('phoneNumber', phone);
    }
    if (name === 'phoneAfter') {
      phone = `${guestInfo.phoneBefore}-${guestInfo.phoneMiddle}-${value}`;
      errorName = 'phoneNumber';
      errorMessage = this.validationCheck('phoneNumber', phone);
    }
    if (name === 'name') {
      errorName = name;
      errorMessage = this.validationCheck(name, value);
    }

    this.setState({
      guestInfo: {
        ...guestInfo,
        [name]: value,
        phoneNumber: phone,
      },
      message: {
        ...message,
        [errorName]: errorMessage,
      },
    });
  }

  scrollToTop = () => window.scrollTo(0, 0);

  endTimeCalc(roomSelectedValue) {
    const { bookingData } = this.state;
    const { data, roomInfo } = bookingData;
    const { startTime, endTime } = data;
    const lastRoomSelect = roomSelectedValue[roomSelectedValue.length - 1];
    if (data.contentId !== 5) {
      return endTime;
    }

    let tempIndex = -1;

    roomInfo.timeList.forEach((item, index) => {
      if (item.id === lastRoomSelect) {
        tempIndex = index;
      }
    });
    const timeList = roomInfo.timeList[tempIndex];
    const timeUnit = timeList.name.replace('分', '');
    const minuts = addMinutes(new Date(`0001/01/01 ${startTime}`), timeUnit);
    return format(minuts, 'HH:mm');
  }

  render() {
    const {
      isLoading, bookingData, activeIndex, key, isComplete, completeData,
      dispEndTime, selectedGolfBox, isLogin, guestInfo, message, isNagataGolf,
    } = this.state;
    const { user } = this.props.common;
    const { classes } = this.props;

    return (
      <div>
        <Loading open={isLoading} />
        <Header />
        {isLogin && (
          <Container>
            <Box display={bookingData && bookingData.errorMessages.length === 0 && !isComplete ? '' : 'none'}>
              <BookingStepper
                activeStep={activeIndex}
              />
              <Box display={activeIndex === 1 ? '' : 'none'}>
                <BookingDisplayDetail
                  isShow={activeIndex === 1}
                  isAgereShow={isNagataGolf && !!guestInfo.name}
                  data={bookingData.data}
                  roomData={bookingData.roomInfo}
                  boxInfo={bookingData.boxInfo}
                  golfType={key.golfTargetType}
                  onBookingListBackClick={this.onBookingListBackClick}
                  onUserNextClick={(
                    event, value, param,
                  ) => this.onFromDispNextClick(event, value, 2, param)}
                />
              </Box>
              <Box display={activeIndex === 2 ? '' : 'none'}>
                <CheckBooking
                  data={bookingData.data}
                  dispEndTime={dispEndTime}
                  selectedGolfBox={selectedGolfBox}
                  user={user && user.user}
                  onBookingDetailBackClick={this.onFromCompleteBack}
                  onCompleteClick={this.onClickComplete}
                  guestInfo={guestInfo}
                />
              </Box>
            </Box>
            <Box display={bookingData && bookingData.errorMessages.length > 0 ? '' : 'none'}>
              <BookingDetailError
                errors={bookingData.errorMessages}
                onBookingListBackClick={this.onBookingListBackClick}
              />
            </Box>
            <Box display={completeData && completeData.errorMessages.length > 0 ? '' : 'none'}>
              <BookingDetailError
                errors={completeData.errorMessages}
                onBookingListBackClick={this.onBookingListBackClick}
              />
            </Box>
            <Box display={isComplete && completeData.errorMessages.length === 0 ? '' : 'none'}>
              <CompleteBooking
                data={completeData.data}
              />
            </Box>
          </Container>
        )}
        {!isLogin && (
          <Container>
            <BookingStepper
              activeStep={0}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} className={classes.gridRoot}>
                <Paper className={classes.paper} variant="outlined">
                  <Box fontWeight="fontWeightBold" align="center" className={classes.title}>
                    会員番号をお持ちの方
                  </Box>
                  <Box mt={2} textAlign="center">
                    <Button color="primary" variant="contained" onClick={this.onClickLogin}>ログインして予約</Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} variant="outlined">
                  <Box fontWeight="fontWeightBold" align="center" className={classes.title}>
                    ゲストの方
                  </Box>
                  <Box>
                    <Box fontWeight="fontWeightBold" mt={4} mb={1}>
                      氏名
                      <RequiredChip />
                    </Box>
                    <TextField
                      id="name"
                      name="name"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      placeholder="氏名"
                      onChange={this.onChangeGuestForm}
                      value={guestInfo.name}
                      error={message.name !== ''}
                      inputProps={{
                        maxLength: 80,
                      }}
                    />
                    <FormErrorText>{message.name}</FormErrorText>
                    <Box fontWeight="fontWeightBold" mt={4} mb={1}>
                      電話番号
                      <RequiredChip />
                    </Box>
                    <FormControl component="fieldset">
                      <Box display="flex">
                        <Box>
                          <TextField
                            name="phoneBefore"
                            autoComplete="off"
                            value={guestInfo.phoneBefore}
                            variant="outlined"
                            onChange={this.onChangeGuestForm}
                            placeholder="025"
                            error={message.phoneNumber !== ''}
                            inputProps={{ maxLength: 5 }}
                          />
                        </Box>
                        <Box p={2}><span>-</span></Box>
                        <Box>
                          <TextField
                            name="phoneMiddle"
                            autoComplete="off"
                            value={guestInfo.phoneMiddle}
                            variant="outlined"
                            onChange={this.onChangeGuestForm}
                            placeholder="2502"
                            error={message.phoneNumber !== ''}
                            inputProps={{ maxLength: 4 }}
                          />
                        </Box>
                        <Box p={2}><span>-</span></Box>
                        <Box>
                          <TextField
                            name="phoneAfter"
                            autoComplete="off"
                            value={guestInfo.phoneAfter}
                            variant="outlined"
                            onChange={this.onChangeGuestForm}
                            placeholder="7112"
                            error={message.phoneNumber !== ''}
                            inputProps={{ maxLength: 4 }}
                          />
                        </Box>
                      </Box>
                      <FormErrorText>{message.phoneNumber}</FormErrorText>
                    </FormControl>
                  </Box>
                  <Box my={2} textAlign="center">
                    <Button color="primary" variant="contained" onClick={this.onClickGuestReservation}>次へ</Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Box textAlign="center" mt={2}>
              <Button
                variant="contained"
                className="custom-button"
                onClick={this.onBookingListBack}
                startIcon={<ArrowBackIosIcon />}
              >
                前の画面に戻る
              </Button>
            </Box>
          </Container>
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, bookingDetail: state.bookingDetail });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(BookingDetail)));
